import {createSlice} from '@reduxjs/toolkit'
import {getAccessToken} from "../helpers/accessTokenManager";

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        accessToken: getAccessToken()
    },
    reducers: {
        setAccessToken: (state, action) => {
            state.accessToken = action.payload;
            if(action.payload === null) {
                window.localStorage.removeItem("accessToken");
            }
            else {
                window.localStorage.setItem("accessToken", action.payload);
            }
        }
    },
})

export const {
    setAccessToken
} = authSlice.actions

export default authSlice.reducer