import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, Routes, Route, Navigate, useLocation} from "react-router-dom";
import {setAccessToken} from "../slices/authSlice";
import {Button, TabsContext, TabsList, TabsTab} from "@seismic/mantle";
import {SlideSearch} from "../tabs/SlideSearch";
import {SlideAssistant} from "../tabs/SlideAssistant";
import {getAccessToken} from "../helpers/accessTokenManager";
import {IntentSearchHost} from "../tabs/IntentSearch";
import {getTenantNameInStorage} from "../helpers/tenantManager";
//import {GoogleSearch} from "../tabs/GoogleSearch";
import {NewSearch} from "../tabs/NewSearch";

const Header = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const accessToken = useSelector(state => state.auth.accessToken);

    useEffect(() => {
        if(accessToken === null)
            navigate({pathname: "/SignIn", search: ""});
    }, [accessToken]);
    
    const signOut = () => {
        dispatch(setAccessToken(null));
    }

    return <div style={{backgroundColor: "white", height: "56px", display: "flex", justifyContent: "space-between", alignItems: "center", padding: "8px"}}>
        <div style={{color: "#22092B", display: "flex", justifyContent: "space-between", alignItems: "center", }}>
            <img height={16} width={16} src="/favicon.ico" alt="icon" /> <span onClick={()=>navigate("/")} style={{paddingLeft: "8px", fontSize: "24px", fontWeight: 700}}>Seismic Search Lab</span>
        </div>
        <div>
            <span style={{paddingRight: "8px"}}>{getTenantNameInStorage()}</span>
            <Button label="Sign Out" onClick={signOut} />
        </div>
    </div>
}

export const Web = () => {
    
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const tab = pathname.indexOf("slide-assistant") >= 0 ? "slide-assistant" :
        pathname.indexOf("intent-search") >= 0 ? "intent-search" :
            pathname.indexOf("google-search") >= 0 ? "google-search" :
                pathname.indexOf("new-search") >= 0 ? "new-search" :
             "slide-search";
    
    const tenant = getTenantNameInStorage();
    
    const showNav = tenant?.toLowerCase() === "quake" && pathname.split("/").length === 2 || pathname.split("/").length === 3 && pathname.split("/")[2] === "";
    //const showNav = false;
    
    const goToRoute = (path) => {
        navigate("/" + path)
    }
    
    useEffect(() => {
        if(getAccessToken() == null || getAccessToken() === "" || getAccessToken() === "null")
            navigate("/SignIn");
    }, []);
    
    return <>
        <Header />
        <div style={{padding: "8px"}}>
            {showNav &&
                <TabsContext value={tab}>
                    <TabsList onChange={goToRoute}>
                        <TabsTab value="new-search">New Search</TabsTab>
                        <TabsTab value="intent-search">Intent Search</TabsTab>
                        <TabsTab value="slide-search">Slide Search</TabsTab>
                        <TabsTab value="slide-assistant">Slide Assistant</TabsTab>
                        {/*                        {/*<TabsTab value="google-search">Google Search</TabsTab>*/}
                    </TabsList>
                </TabsContext>
            }
            <div style={{paddingTop: "16px"}}>
                <Routes>
                    <Route path="/new-search/*" element={<NewSearch />} />
                    <Route path="/intent-search/*" element={<IntentSearchHost />} />
                    <Route path="/slide-search/*" element={<SlideSearch />} />
                    <Route path="/slide-assistant/*" element={<SlideAssistant />} />
                    {/*<Route path="/google-search/*" element={<GoogleSearch />} />*/}
                    <Route path="*" element={<Navigate to="/new-search" replace />} />
                </Routes>
            </div>
        </div>
    </>
}