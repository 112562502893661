export const setTenantNameInStorage = (tenantName) => {
    if(tenantName === null || tenantName === '') {
        window.localStorage.removeItem("tenantName")
    }
    else {
        window.localStorage.setItem("tenantName", tenantName);
    }
}

export const getTenantNameInStorage = () => {
    return window.localStorage.getItem("tenantName");
}