import React, {useEffect} from "react";
import {
    alertColors,
    Button,
    IconSearch,
    Input,
    Spinner,
    Tree,
    Modal,
    Tooltip,
    Checkbox, IconUserGroup
} from "@seismic/mantle";
import {newSearch, getPreviousSearchTerms, explainQuery, getUsers, getPeers} from "../api/client";
import "./NewSearch.css"
import {DateTime} from "luxon";
import {getTenantNameInStorage} from "../helpers/tenantManager";


const getBorder = (distance) => {     
    return distance === 0 ? "8px solid green" : (distance < 0 || distance > 0) ? "8px solid lightgreen" : "1px solid #cfd4da";
}

const ContentUsage = ({clickStats}) => {
    return <div>
        <table>
            <thead>
            <tr>
                <th></th>
                <th>1 Day</th>
                <th>3 Day</th>
                <th>7 Day</th>
                <th>14 Day</th>
                <th>30 Day</th>
                <th>60 Day</th>
                <th>90 Day</th>
                <th>180 Day</th>
                <th>365 Day</th>
                <th>Total</th>
                <th>Last</th>
                <th>Frecency</th>
            </tr>
            </thead>
            <tbody>
            {Object.entries(clickStats).map(([key, value], i)=>{
                return <tr key={i}>
                    <td>{key}</td>
                    {value?.total === 0 ? <td colSpan={12}>No Activity</td> : <>
                <td>{value?.action1}</td>
                <td>{value?.action3}</td>
                <td>{value?.action7}</td>
                <td>{value?.action14}</td>
                <td>{value?.action30}</td>
                <td>{value?.action60}</td>
                <td>{value?.action90}</td>
                <td>{value?.action180}</td>
                <td>{value?.action365}</td>
                <td>{value?.total}</td>
                <td>{value?.lastOccurredAt == null ? "N/A" : DateTime.fromISO(value.lastOccurredAt.replace(" ", "T")).toRelative()}</td>
                <td>{value?.frecencyScore}</td>
                    </>}
            </tr>})}
            </tbody>
        </table>
    </div>
}

const SearchResult = ({prefix, index, contentId, contentVersionId, name, query, version, score, thumbnail, totalClicks, clickStats, avgPosition, distance, promoted, versionCreatedDate, setScoreExplanation, showStats}) => {
    
    const [localShowStats, setLocalShowStats] = React.useState(false);
    
    useEffect(()=>{
        setLocalShowStats(showStats);
    }, [showStats])
    
    const highlight = (e) => {
        try {
            let contentId = "_" + e.currentTarget.id.split("_")[1].replace("-", "");
            let classes = document.getElementsByClassName(contentId)
            for(let i = 0; i < classes.length; i++) {
                classes[i].style.backgroundColor = "#E0E6FF";
            }
        } catch (e) {
            console.log(e);
        }
    }

    const unHighlight = (e) => {
        try {
            let contentId = "_" + e.currentTarget.id.split("_")[1].replace("-", "");
            let classes = document.getElementsByClassName(contentId)
            for(let i = 0; i < classes.length; i++) {
                classes[i].style.backgroundColor = 'white';
            }
        }
        catch (e) {
            console.log(e);
        }
    }
    
    return <div style={{paddingTop: "8px"}}>
        <div id={prefix+"_"+contentId} onClick={()=>setLocalShowStats(!localShowStats)} onMouseOver={highlight} onMouseLeave={unHighlight} className={`box _${contentId?.replace("-", "")}`} style={{border: "1px solid #cfd4da", borderRadius: "8px", backgroundColor: "white", borderLeft: getBorder(distance)}}>
            <div style={{display: "flex", alignItems: "", gap: "8px", padding: "12px"}}>
                <Tooltip inline={false} variant="light" size="lg" content={<ContentUsage clickStats={clickStats} />}>
                    <span>
                        <img loading="lazy" src={thumbnail} alt="thumbnail" height={32} width={32} />        
                    </span>
                </Tooltip>
                <div style={{width: "100%"}}>
                    <div style={{display: "flex", justifyContent: "space-between"}}>
                        <a target="_blank" href={`https://${getTenantNameInStorage()}.seismic.com/app/#/content/${contentId}`}><div style={{maxWidth: "350px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>{promoted && <>🔒</>}{index}. {name}</div></a>
                        {score && <small onClick={()=>setScoreExplanation({id: contentVersionId, query: query, name: name})} style={{cursor: "pointer", color: "blue", whiteSpace: "no-wrap"}}>Score: {score.toLocaleString()}</small>}
                    </div>
                    {/*<div style={{color: "#666666"}}>{type} - {format}</div>*/}
                    <div style={{display: "flex", justifyContent: "space-between"}}>
                    {totalClicks && <div style={{color: "#666666"}}>{totalClicks.toFixed()} total clicks - {avgPosition.split(".")[0]} average position</div>}
                        {distance != null && <div style={{color: "#666666"}}>{distance === "not found" ? "No search clicks in 90+ days." : <span>Click Volume Position: <span style={{backgroundColor: "#f3f4f6"}}>{(distance === 0 ? "" : distance < 0 ? "↓" : "↑")}{distance === 0 ? "Perfect" : Math.abs(distance)}</span></span>}</div>}
                        {version && <small title={"Version: " + version} style={{cursor: "pointer", backgroundColor: "#f3f4f6", borderRadius: "2px"}}>Version Created:<br/>{versionCreatedDate?.split("T")[0] ?? "N/A"}</small>}
                        <small style={{cursor: "pointer", backgroundColor: "#f3f4f6", borderRadius: "2px"}}>Last Action:<br/>{clickStats["Global User Activity"].lastOccurredAt == null ? "N/A" : DateTime.fromISO(clickStats["Global User Activity"].lastOccurredAt.replace(" ", "T")).toRelative()}</small>
                        <small style={{cursor: "pointer", backgroundColor: "#f3f4f6", borderRadius: "2px"}}>Last Searched:<br/>{clickStats["Global Search Clicks"].lastOccurredAt == null ? "N/A" : DateTime.fromISO(clickStats["Global Search Clicks"].lastOccurredAt.replace(" ", "T")).toRelative()}</small>
                    </div>
                </div>
            </div>
            {localShowStats && <div style={{padding:"8px"}}><ContentUsage clickStats={clickStats} /></div>}

        </div>
    </div>
}

const PeersDialog = ({peers, showPeers, setShowPeers}) => {
    return <Modal
        open={showPeers}
        onClose={() => setShowPeers(false)}
        header={`Peers`}
        closeOnOutsideClick
    >
        <div>
            {peers == null && <div>Loading...</div>}
            {peers && <div>
                <table>
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>Organization</th>
                        <th>Title</th>
                    </tr>
                    </thead>
                    <tbody>
                    {peers.map((a,i)=><tr key={i}>
                        <td>{a.FULLNAME}</td>
                        <td>{a.ORGANIZATION}</td>
                        <td>{a.TITLE}</td>
                    </tr>)}
                    </tbody>
                </table>
            </div>}
        </div>
    </Modal>
}

const ScoreExplanationDialog = ({scoreExplanation, setScoreExplanation}) => {

    const nodeIfy = (explain, currentId = "x", parentId = undefined, depth = 0, nodes = {}) => {

        nodes[currentId] = {
            name: explain.value.toLocaleString() + " (" + explain.description.replace("of:", "of below") + ")",
            parentId: parentId,
            value: explain.value
        };
        if (explain.details) {
            explain.details.forEach((d, x) => {
                nodeIfy(d, currentId + "_" + x, currentId, depth + 1, nodes)
            });
        }
        return nodes;
    }

    const [busy, setBusy] = React.useState(false);
    const [explain, setExplain] = React.useState(null);

    const doIt = async () => {
        setBusy(true)
        try {
            let res = await explainQuery({
                id: scoreExplanation.id,
                query: scoreExplanation.query,
            })
            console.log("res", res)
            let nodes = nodeIfy(res.explanation)
            console.log("nodes", nodes)
            setExplain(nodes)
        } finally {
            setBusy(false)
        }
    }
    
    useEffect(()=>{
        doIt();
    }, [JSON.stringify(scoreExplanation)])

    return     <Modal
                size="xl"
                open={scoreExplanation !== null}
                onClose={() => setScoreExplanation(null)}
                header={`${scoreExplanation?.name} - Score Explanation`}
                closeOnOutsideClick
            >
        <div>
            {busy && <div>Loading...</div>}
            {!busy && scoreExplanation && explain && <Tree
                renderLabel={({ node }) => node.name}
                getInitialHiddenIds={context => {
                    const hiddenIds = new Set()
                    console.log("context", context)
                    context.traverseDepthFirst((id, depth) => {
                        //let node = context.nodeById[id];
                        //if (node.value < 1000) {
                        if(depth > 3) {
                            hiddenIds.add(id)
                        }
                    })
                    return hiddenIds
                }}
                nodeById={explain}
            />}
        </div>
    </Modal>
}

export const NewSearch = () => {
    
    const [query, setQuery] = React.useState("");
    const [busy, setBusy] = React.useState(false);
    const [error, setError] = React.useState(null);
    const [previousSearchTerms, setPreviousSearchTerms] = React.useState([]);
    const [showPeers, setShowPeers] = React.useState(false);
    const [peers, setPeers] = React.useState([{name: "a", organization: "b", title: "c"}]);
    const [results, setResults] = React.useState(null);
    const [users, setUsers] = React.useState([]);
    const [selectedUser, setSelectedUser] = React.useState(null);
    const [scoreExplanation, setScoreExplanation] = React.useState(null);
    const methods = [
        "no-change",
        "danny-method-global-and-cohort",
        "danny-method-global",
        "danny-method-cohort",
        "remove-raw-boost",
        "decrease-raw-boost",
        "search-history-global-frecency",
        "search-history-cohort-frecency",
        "search-history-user-frecency",
        "search-history-global-clicks-90-days",
        "search-history-cohort-clicks-90-days",
        "search-history-user-clicks-90-days",
        "useractivity-global-frecency",
        "useractivity-cohort-frecency",
        "useractivity-user-frecency",
        "useractivity-global-actions-90-days",
        "useractivity-cohort-actions-90-days",
        "useractivity-user-actions-90-days",
    ]
    
    const [selectedMethod, setSelectedMethod] = React.useState(methods[0]);
    const [showStats, setShowStats] = React.useState(false);

    const draw = (id) => {
        const canvas = document.getElementById('connecting-lines');
        const ctx = canvas.getContext('2d');
        const firstColumnItems = document.querySelectorAll('#first-column .box');
        const middleColumnItems = document.querySelectorAll('#middle-column .box');
        const lastColumnItems = document.querySelectorAll('#last-column .box');

        let extraHeight = canvas.parentElement.getBoundingClientRect().y;

        canvas.width = canvas.parentElement.offsetWidth;
        canvas.height = canvas.parentElement.offsetHeight;
        
        ctx.fillStyle = "transparent";
        ctx.strokeStyle = "#cfd4da";
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        firstColumnItems.forEach(item => {
            const matchingItem = Array.from(middleColumnItems).find(mItem => mItem.id.split("-")[1] === item.id.split("-")[1]);
            if (matchingItem) {
                const start = item.getBoundingClientRect();
                const end = matchingItem.getBoundingClientRect();

                ctx.beginPath();
                ctx.moveTo(start.right -8, (start.top + start.height / 2) - extraHeight);
                ctx.lineTo(end.left -8, (end.top + end.height / 2) - extraHeight);
                ctx.stroke();
            }
        });

        lastColumnItems.forEach(item => {
            const matchingItem = Array.from(middleColumnItems).find(mItem => mItem.id.split("-")[1] === item.id.split("-")[1]);
            if (matchingItem) {
                const start = matchingItem.getBoundingClientRect();
                const end = item.getBoundingClientRect();

                ctx.beginPath();
                ctx.moveTo(start.right -8, (start.top + start.height / 2) - extraHeight);
                ctx.lineTo(end.left -8, (end.top + end.height / 2) - extraHeight);
                ctx.stroke();
            }
        });
    }
    
    const doSearch = (explicitQuery) => {
        let theQuery = explicitQuery || query;
        setResults(null);
        setBusy(true);
        setError(null);
        newSearch(theQuery, selectedMethod, selectedUser)
            .then(res => setResults(res))
            .then(() => draw())
            .catch(error => setError('error', error))
            .finally(() => setBusy(false));
    }

    const maybeDoSearch = (e) => {
        if (e.key !== "Enter") return;
        doSearch(null);
    }
    
    useEffect(() => {
        setBusy(true);
        setError(null);
        getPreviousSearchTerms().
        then(res => setPreviousSearchTerms(res))
            .catch(error => setError('error', error))
            .finally(() => setBusy(false));
        getUsers().
        then(res => {
            setUsers(res);
            setSelectedUser(res[0].id)
            })
            .catch(error => setError('error', error))
    }, []);

    useEffect(() => {
        function handleResize() {
            draw();
        }
        window.addEventListener('resize', handleResize)
        return _ => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])
    
    const hasIdealResults = results && results.idealResults && results.idealResults.length > 0;
    
    const showPeersDialog = () => {
        setPeers(null);
        getPeers(selectedUser).then(res => setPeers(res));
        setShowPeers(true)
    }

    return <div>
        <ScoreExplanationDialog scoreExplanation={scoreExplanation} setScoreExplanation={setScoreExplanation} />
        <PeersDialog peers={peers} showPeers={showPeers} setShowPeers={setShowPeers} />
        <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
            <div style={{display: "flex", gap: "4px", alignItems: "center"}}>
                <Button startAdornment={IconUserGroup} label="Peers" hideLabel onClick={showPeersDialog} />
                {users != null && users.length > 1 && <select style={{width: "240px"}} onChange={(e)=> {setSelectedUser(e.target.value);}}>
                    {users.map((a,i)=><option value={a.id} key={i}>{a.name} ({a.organization} - {a.title})</option>)}
                </select>}
                <select style={{width: "240px"}} onChange={(e)=> {setQuery(e.target.value);doSearch(e.target.value);}}>
                    <option>Select a previous query...</option>
                    {previousSearchTerms.map((a,i)=><option value={a.query} key={i}>{a.query} ({a.total})</option>)}
                </select>
                <Input startAdornment={IconSearch} onKeyDown={maybeDoSearch} style={{width: "600px"}} type="text"
                       value={query} onChange={e => setQuery(e.target.value)} placeholder="Search for something..."/>
                <select value={selectedMethod} onChange={(e)=>setSelectedMethod(e.target.value)} style={{width: "160px"}}>
                    {methods.map((a,i)=><option value={a} key={i}>{a}</option>)}
                </select>
                <Button startAdornment={busy && <Spinner size={16}/>} label="Submit" onClick={() => doSearch(null)}/>
                <Checkbox checked={showStats} onChange={(e)=>setShowStats(e.target.checked)} />
                Show Stats
                {error && <div style={{color: alertColors.error}}>{error}</div>}
            </div>
        </div>
        <div id="container">
            {results && <div id="columns" style={{display: "flex", gap: "24px", paddingTop: "8px"}}>
                <div id="first-column" style={{width: hasIdealResults ? "33%" : "50%"}}>
                    <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                        <h3>Existing</h3>
                        <small>Search Took {results?.timings?.legacySearchTook?.toFixed(3)} sec</small>
                        <h5>nDCG {results.existingNormalizedDcg?.toFixed(4)}</h5>
                    </div>
                    <div>
                        {results.legacyResults.map((a,i)=><SearchResult showStats={showStats} prefix="left" key={"left" + a.contentId} index={i+1} {...a} query={results.legacyResultsQuery} setScoreExplanation={setScoreExplanation} />)}
                    </div>
                </div>
                {hasIdealResults && <div id="middle-column" style={{width: "33%"}}>
                    <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                        <h3>Click Volume (90 Days)</h3>
                        <small>Ideal Order {results?.timings?.idealOrderTook?.toFixed(3)} sec</small>
                        <small>Connection {results?.timings?.connectionTook?.toFixed(3)} sec</small>
                        <h5></h5>
                    </div>
                    <div>
                        {results.idealResults.map((a,i)=><SearchResult showStats={showStats} prefix="middle" key={"middle" + a.contentId} index={i+1} {...a} />)}
                    </div>
                </div>}
                <div id="last-column" style={{width: hasIdealResults ? "33%" : "50%"}}>
                    <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                        <h3>New</h3>
                        <small>Search Took {results?.timings?.newSearchTook?.toFixed(3)} sec</small>
                        <h5>nDCG {results.newNormalizedDcg?.toFixed(4)}</h5>
                    </div>
                    <div>
                        {results.newResults.map((a,i)=><SearchResult showStats={showStats} prefix="right" key={"right" + a.contentId} index={i+1} {...a} query={results.newResultsQuery} setScoreExplanation={setScoreExplanation} />)}
                    </div>
                </div>
            </div>}
            <canvas id="connecting-lines"></canvas>
        </div>
    </div>
}