export const addStandardCases = (builder, fetch, setStatus, setLoadingPayload, setPayload, setError) => {
    builder
        .addCase(fetch.pending, (state, action) => {
            setStatus("loading", state);
            setLoadingPayload(state);
        })
        .addCase(fetch.fulfilled, (state, action) => {
            try {
                setStatus("succeeded", state);
                setPayload(action.payload, state);
            } catch(e) {
                setStatus("failed", state)
                setError(e.toString(), state);
            }
        })
        .addCase(fetch.rejected, (state, action) => {
            setStatus("failed", state)
            setError(action.error.message, state);
        })
    return builder;
}