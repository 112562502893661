import React, {useState, useEffect} from "react";
import {getTenantNameInStorage, setTenantNameInStorage} from "../helpers/tenantManager";
import {useNavigate} from "react-router-dom";
import {setAccessToken} from '../slices/authSlice'
import {useDispatch} from 'react-redux'
import {Button} from "@seismic/mantle";

export const SignIn = () => {

    const [tenantName, setTenantName] = useState(getTenantNameInStorage());
    const navigate = useNavigate();
    const dispatch = useDispatch()

    const getHashParams = () => {
        const hashParams = {};
        const r = /([^&;=]+)=?([^&;]*)/g;
        const q = window.location.hash.substring(1);
        let e = r.exec(q);
        while (e) {
            hashParams[e[1]] = decodeURIComponent(e[2]);
            e = r.exec(q);
        }
        return hashParams;
    }

    const getQueryStringParams = () => {
        const queryStringParams = {};
        const r = /([^&;=]+)=?([^&;]*)/g;
        const q = window.location.search.substring(1);
        let e = r.exec(q);
        while (e) {
            queryStringParams[e[1]] = decodeURIComponent(e[2]);
            e = r.exec(q);
        }
        return queryStringParams;
    }

    const signInClicked = () => {
        let additionalParameters = tenantName !== "quake" ? "&authentication_mode=directlogon" : "";
        let redirect = getQueryStringParams().redirect;
        let clientId = process.env.REACT_APP_SEISMIC_CLIENT_ID;
        let redirectUrl = window.location.protocol + '//' + window.location.host + `/Auth${(redirect != null ? `?redirect=${redirect}` : "")}`;
        let scopes = process.env.REACT_APP_SEISMIC_SCOPES;
        //todo: care about nonce/state
        let nonce = Math.random();
        setTenantNameInStorage(tenantName);
        const url = `https://auth.seismic.com/tenants/${tenantName}/connect/authorize?client_id=${clientId}&redirect_uri=${encodeURIComponent(redirectUrl)}&response_type=id_token token&scope=${encodeURIComponent(scopes)}&nonce=${encodeURIComponent(nonce)}${additionalParameters}`;
        window.location.replace(url);
    }

    const loadAccessToken = () => {
        let accessToken = getHashParams().access_token;
        let redirect = getQueryStringParams().redirect;
        if (accessToken != null) {
            dispatch(setAccessToken(accessToken));
            if(redirect != null) {
                navigate({pathname: "/" + redirect, search: ""}); //Clear the stuff out of the URL
            }
            else {
                navigate({pathname: ".", search: ""}); //Clear the stuff out of the URL
            }
        }
    }

    useEffect(() => {
        loadAccessToken();
    }, []);


    return <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100vh"}}>
        <div style={{backgroundColor: "white", border: "1px solid #CFD4DA", borderRadius: "8px"}}>
            <div style={{padding: "16px", display: "flex", alignItems: "center", borderBottom: "1px solid #CFD4DA"}}>
                <img style={{paddingRight: "8px"}} src="https://seismic.com/favicon.ico" alt="Seismic" />
                <div style={{fontSize: "16px", fontWeight: 600}}>Seismic Search Lab - Sign In</div>
            </div>
            <div style={{padding: "16px"}}>
                <div>
                    <label style={{paddingRight: "8px"}} htmlFor="a">Tenant</label>
                    <input style={{border: "1px solid #CFD4DA", width: "275px"}} id="a" onChange={e => setTenantName(e.target.value)} type="text" defaultValue={tenantName}/>
                </div>
            </div>
            <div style={{padding: "8px"}}>
                <Button style={{width: "100%"}} variant="primary" label="Sign In" onClick={signInClicked}/>
            </div>
        </div>
    </div>
}