import React, {useEffect} from "react";
import {
    alertColors,
    Button,
    IconSearch,
    IllustrationNoSearchResults,
    Input,
    Spinner,
} from '@seismic/mantle'
import "./SlideSearch.css"
import {useDispatch, useSelector} from "react-redux";
import {
    appendGenerativeText,
    clearGenerativeText,
    generativeTextComplete,
    fetchContent,
    fetchGenerativeAi2,
    fetchKnowledge,
    fetchLayout,
    fetchLessons,
    fetchLiveSocialContent,
    fetchWorkspaceContent,
    removeSearchHistory,
    setQuery, fetchTenantInfo, noLessons, noKnowledge, appendSearchHistory
} from "../slices/searchSlice";
import {CenteredSpinner2} from "../components/CenteredSpinner";
import {LayoutSelector} from "../components/search/layouts/LayoutSelector";

export const IntentSearch = () => {

    const dispatch = useDispatch();
    const query = useSelector(state => state.search.query);
    const lessonsStatus = useSelector(state => state.search.lessonsStatus);
    const lessonsError = useSelector(state => state.search.lessonsError);
    const lessons = useSelector(state => state.search.lessons);
    const liveSocialContentStatus = useSelector(state => state.search.liveSocialContentStatus);
    const liveSocialContentError = useSelector(state => state.search.liveSocialContentError);
    const liveSocialContent = useSelector(state => state.search.liveSocialContent);
    const contentStatus = useSelector(state => state.search.contentStatus);
    const contentError = useSelector(state => state.search.contentError);
    const content = useSelector(state => state.search.content);
    const workspaceContentStatus = useSelector(state => state.search.workspaceContentStatus);
    const workspaceContentError = useSelector(state => state.search.workspaceContentError);
    const workspaceContent = useSelector(state => state.search.workspaceContent);
    const slidesStatus = useSelector(state => state.search.slidesStatus);
    const slidesError = useSelector(state => state.search.slidesError);
    const slides = useSelector(state => state.search.slides);
    const knowledgeStatus = useSelector(state => state.search.knowledgeStatus);
    const knowledgeError = useSelector(state => state.search.knowledgeError);
    const knowledge = useSelector(state => state.search.knowledge);
    const layoutStatus = useSelector(state => state.search.layoutStatus);
    const layoutError = useSelector(state => state.search.layoutError);
    const layout = useSelector(state => state.search.layout);
    const tenantInfo = useSelector(state => state.search.tenantInfo);
    const searchHistory = useSelector(state => state.search.searchHistory);

    const error = lessonsError || liveSocialContentError || contentError || workspaceContentError || slidesError || knowledgeError || layoutError;
    const busy = contentStatus === "loading" || workspaceContentStatus === "loading" || slidesStatus === "loading" || knowledgeStatus === "loading" || layoutStatus === "loading";

    const getData = () => ({
        lessonsStatus: lessonsStatus,
        lessonsError: lessonsError,
        lessons: lessons,
        liveSocialContentStatus: liveSocialContentStatus,
        liveSocialContentError: liveSocialContentError,
        liveSocialContent: liveSocialContent,
        contentStatus: contentStatus,
        contentError: contentError,
        content: content,
        workspaceContentStatus: workspaceContentStatus,
        workspaceContentError: workspaceContentError,
        workspaceContent: workspaceContent,
        slidesStatus: slidesStatus,
        slidesError: slidesError,
        slides: slides,
        knowledgeStatus: knowledgeStatus,
        knowledgeError: knowledgeError,
        knowledge: knowledge
    });
    
    const doSearch = async (explicitQuery) => {
        dispatch(clearGenerativeText());
        dispatch(appendSearchHistory(explicitQuery || query));
        Promise.all([
            dispatch(fetchLayout()),
            dispatch(tenantInfo.lessonlyEnabled ? fetchLessons() : noLessons()),
            dispatch(fetchLiveSocialContent()),
            dispatch(fetchContent()),
            dispatch(fetchWorkspaceContent()),
            dispatch(tenantInfo.knowledgeEnabled ? fetchKnowledge() : noKnowledge()),
        ]).then(a=>{

            const urlParams = new URLSearchParams(window.location.search);
            const myParam = urlParams.get('skipGenerative');
            if(myParam === "true") {
                dispatch(appendGenerativeText("Generative Text Skipped")).then(() => {
                    dispatch(generativeTextComplete());
                })
            } 
            else {
                let items = [];
                for (let i = 0; i < a.length; i++) {
                    if (a[i] && a[i].payload && a[i].payload.length > 0) {
                        items = items.concat(a[i].payload.slice(0, 3));
                    }
                }
                let body = {
                    query: explicitQuery || query,
                    items: items,
                };
                dispatch(fetchGenerativeAi2(body));
            }
        }).catch(e=>{
        });
        //dispatch(fetchSlides());
    }

    const maybeDoSearch = async (e) => {
        if (e.key !== "Enter") return;
        await doSearch(null);
    }
    
    const appendData = (title, arr, titleLabel = "Name", descriptionLabel = "Description", textLabel = "Text") => {
        if(!arr || arr.length === 0) return "";
        let text = "";
        text += "--------------------------------\n"
        text += title + "\n";
        text += "--------------------------------\n"
        for(let i = 0; i < arr.length; i++) {
            text += titleLabel + ": " + arr[i].title + "\n";
            if(arr[i].description)
                text += descriptionLabel + ": " + arr[i].description + "\n";
            if(arr[i].text)
                text += textLabel + ": " + arr[i].text + "\n";
            text += "\n";
        }
        return text;
    }
    
    const downloadText = async (e) => {
        e.preventDefault();
        let data = getData();
        
        let text = "";
        //lessons
        text += appendData("Lessons", data.lessons);
        //liveSocialContent
        text += appendData("Live Social", data.liveSocialContent);
        //content
        text += appendData("Published Content", data.content);
        //workspaceContent
        text += appendData("Workspace Content", data.workspaceContent);
        //slides
        text += appendData("Slides", data.slides);
        //knowledge
        text += appendData("Knowledge", data.knowledge, "Question", "Answer");
        
        let blob = new Blob([text], {type: "text/plain"});
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        a.href = url;
        a.download = `${query}.txt`;
        a.click();
        window.URL.revokeObjectURL(url);
    }

    return <>
        <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
            <div style={{display: "flex", gap: "4px", alignItems: "center"}}>
                <Input startAdornment={IconSearch} onKeyDown={maybeDoSearch} style={{width: "600px"}} type="text"
                       value={query} onChange={e => dispatch(setQuery(e.target.value))} placeholder="Search for slides or ask a question..."/>
                <Button startAdornment={busy && <Spinner size={16}/>} label="Submit" onClick={() => doSearch(null)}/>
                <Button disabled={busy} label="Download Text" onClick={downloadText}/>
                {error && <div style={{color: alertColors.error}}>{error}</div>}
            </div>
        </div>
        <div>{searchHistory != null && searchHistory.length > 0 && <span>Previous Searches: </span>}{searchHistory?.map((searchItem, i)=><>{i > 0 && <span style={{padding: "0px 8px"}}>|</span>}<a style={{cursor: "pointer"}} onContextMenu={(e)=>{dispatch(removeSearchHistory(searchItem)); e.preventDefault();}} onClick={()=>{dispatch(setQuery(searchItem));doSearch(searchItem);}}>{searchItem}</a></>)}</div>
        <hr/>
        {layoutStatus === "idle" ? <div style={{display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "75vh"}}>
            <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                <div style={{marginBottom: -60, fontSize: "24px", fontWeight: 600, color: "#343a40"}}>Search for something</div>
                <IllustrationNoSearchResults size="lg" />
            </div>
        </div> : layout == null ? <CenteredSpinner2 /> : <LayoutSelector layout={layout} data={getData()} />}
    </>
}

export const IntentSearchHost = () => {

    const dispatch = useDispatch();
    const tenantInfo = useSelector(state => state.search.tenantInfo);
    
    useEffect(() => {
        dispatch(fetchTenantInfo());
    }, []);
    
    return tenantInfo == null ? <CenteredSpinner2 /> : <IntentSearch />;
    
}