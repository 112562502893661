import {getAccessToken} from "../helpers/accessTokenManager";
import { fetchEventSource } from '@microsoft/fetch-event-source';

const urlPrefix = !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? "http://127.0.0.1:1234" : "";

const gotoSignIn = () => {
    window.location = '/SignIn';
}

const get = async (path) => {
    
    const accessToken = getAccessToken();
    
    if(accessToken == null || accessToken === "" || accessToken === "null") {
        gotoSignIn();
        return;
    }
    
    let response = await fetch(path, {
        "headers": {
            "Authorization": "Bearer " + accessToken,
        },
        "method": "GET"
    });
    if (!response.ok) {
        let error = await response.json();
        if(error.status === 401)
            gotoSignIn();
        throw new Error(error.message);
    }
    return await response.json();
}

const post = async (path, body, token = null) => {

    const accessToken = token || getAccessToken();
    
    let response = await fetch(path, {
        "headers": {
            "Authorization": "Bearer " + accessToken,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        "method": "POST",
        "body": JSON.stringify(body)
    });
    if (!response.ok) {
        let error = await response.json();
        if(error.status === 401) {
            gotoSignIn();
        }
        throw new Error(error.message);
    }
    return await response.json();
}

const stream = async (path, onMessage, onError, onClose, method = "GET", body = null) => await fetchEventSource(path, {
    method: method,
    body: body == null ? undefined : JSON.stringify(body),
    headers: {
        'Authorization': 'Bearer ' + getAccessToken(),
        'Content-Type': 'application/json'
    },
    onmessage(msg) {
        onMessage(msg);
    },
    onerror(err) {
        console.error("Stream failed:", err);
        onError(err);
        throw err;
    },
    onclose() {
        onClose();
    }
});


export const teams = async (query) => await get(`${urlPrefix}/teams?q=${query}`);
export const search = async (query) => await get(`${urlPrefix}/search?q=${query}`);

//Search POC
export const getLessons = async (query) => await get(`${urlPrefix}/lessonly_search?q=${query}`);
export const getSlides = async (query) => await get(`${urlPrefix}/search?q=${query}&searchType=hybrid`);
export const getKnowledge  = async (query) => await get(`${urlPrefix}/knowledge_search?q=${query}`);
export const getLiveSocialContent = async (query) => await get(`${urlPrefix}/livesocial_search?q=${query}`);
export const getContent = async (query) => await get(`${urlPrefix}/content_search?q=${query}`);
export const getWorkspaceContent = async (query) => await get(`${urlPrefix}/workspace_content_search?q=${query}`);
export const getLayout = async (query) => await get(`${urlPrefix}/layout?q=${query}`);
export const getTenantInfo = async () => await get(`${urlPrefix}/tenant_info`);
export const newSearch = async (query, method, userId) => await get(`${urlPrefix}/new_search?q=${query}&method=${method}&userId=${userId}`);
export const getPreviousSearchTerms = async () => await get(`${urlPrefix}/previous_search_terms`);

export const getUsers = async () => await get(`${urlPrefix}/users`);
export const getPeers = async (userId) => await get(`${urlPrefix}/peers?userId=${userId}`);

export const explainQuery = async (body) => await post(`${urlPrefix}/explain`, body);

export const streamGenerativeAi = async (query, onMessage, onError, onClose) => await stream(`${urlPrefix}/generative_ai?q=${query}`, onMessage, onError, onClose);
export const streamGenerativeAi2 = async (body, onMessage, onError, onClose) => await stream(`${urlPrefix}/generative_ai2`, onMessage, onError, onClose, "POST", body);