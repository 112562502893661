import {getTenantNameInStorage} from "./tenantManager";


export const setSearchHistory = (searchHistory) => {
    let tenantName = getTenantNameInStorage();
    if(searchHistory === null || searchHistory.length === 0) {
        window.localStorage.removeItem(tenantName + "_searchHistory")
    }
    else {
        window.localStorage.setItem(tenantName + "_searchHistory", searchHistory);
    }
}

export const getSearchHistory = () => {
    let tenantName = getTenantNameInStorage();
    let toReturn = window.localStorage.getItem(tenantName + "_searchHistory");
    if(toReturn == null || toReturn === '')
        return [];
    return toReturn.split(',');
}