import React from "react";
import {Entity, IconEmoji, IconStatusClose, neutralColors} from "@seismic/mantle";

export const BulletPoints = ({query, bulletPoints}) => {

    return <div style={{
        backgroundColor: neutralColors.white,
        padding: "8px",
        maxWidth: "1575px",
        borderRadius: "4px",
        border: `1px solid ${neutralColors.silver}`
    }}>
        <Entity
            primaryActions={[
                {
                    label: 'Good',
                    startAdornment: IconEmoji,
                    onClick: () => alert('Good'),
                },
                {
                    label: 'Bad',
                    startAdornment: IconStatusClose,
                    onClick: () => alert('Bad'),
                },
            ]}
            imgProps={{
                src: 'https://seismic.com/favicon.ico',
                alt: 'Seismic',
            }}
            children={<div>{bulletPoints.map(a => <div>&#x2022; {a}</div>)}</div>}
            subject={query}
        />
    </div>
}