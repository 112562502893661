import React, {useState} from "react";
import {
    alertColors,
    Button,
    IconSearch,
    IllustrationNothingToView,
    Input,
    Spinner,
} from '@seismic/mantle'
import "./SlideSearch.css"
import {Summary} from "../components/Summary";
import {BulletPoints} from "../components/BulletPoints";
import {SlideResults} from "../components/SlideResults";
import {teams} from "../api/client";

const getRandomQuery = () => {
    const list = [
        "How does Seismic work with Microsoft Outlook?",
        "How does Seismic integrate with Gmail?",
        "What is Seismic for meetings?",
        "When will Seismic for meetings be released?",
        "What is the difference between Seismic Professional and Enterprise?",
        "What is guided assembly?",
        "What is a dsr?",
        "gdpr",

    ];
    return list[Math.floor(Math.random() * list.length)];
}

export const SlideSearch = () => {

    const [busy, setBusy] = useState(false);
    const [error, setError] = useState(null);
    const [query, setQuery] = useState(""); //getRandomQuery()
    const [results, setResults] = useState(null);

    const doSearch = async () => {
        setError(null);
        setBusy(true);
        setResults(null)
        try {
            let results = await teams(query);
            setResults(results);
            setBusy(false);
        } catch (e) {
            setError(e.message);
            setBusy(false);
        }
    }

    const maybeDoSearch = async (e) => {
        if (e.key !== "Enter") return;
        await doSearch();
    }

    return <>
        <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
            <div style={{display: "flex", gap: "4px", alignItems: "center"}}>
                <Input startAdornment={IconSearch} onKeyDown={maybeDoSearch} style={{width: "600px"}} type="text"
                       value={query} onChange={e => setQuery(e.target.value)} placeholder="Search for slides or ask a question..."/>
                <Button startAdornment={busy && <Spinner size={16}/>} label="Submit" onClick={doSearch}/>
                {error && <div style={{color: alertColors.error}}>{error}</div>}
            </div>
            {results && <div style={{display: "flex", justifyContent: "space-between"}}>
                <small><b><i>{results.query}</i></b> took {results.elapsed.toLocaleString()} seconds
                    {results.totalTokens === 0 ? <> (AI data from cache)</> : <> for {results.totalTokens.toLocaleString()} tokens and cost ${results.cost.toLocaleString()} using {results.model}</>}</small>
            </div>}
        </div>
        <hr/>
        {results && <div>
            <div style={{paddingBottom: "8px"}}>
                <Summary summary={results.summary}/>
            </div>
            <div style={{paddingBottom: "0px"}}>
                <BulletPoints query={results.query} bulletPoints={results.bulletPoints}/>
            </div>
            <div style={{paddingBottom: "0px"}}>
                <SlideResults results={results.sources}/>
            </div>
        </div>}
        {results === null && <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
            <div style={{height: "50%", width: "50%"}}>
                <IllustrationNothingToView/>
            </div>
        </div>}
    </>
}