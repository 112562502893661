import React, {useState, useMemo, useEffect} from "react";
import debounce from 'lodash.debounce';
import {
    alertColors,
    IconSearch,
    IllustrationNothingToView,
    Input,
} from '@seismic/mantle'
import "./SlideSearch.css"
import {SlideResults} from "../components/SlideResults";
import {search} from "../api/client";

export const SlideAssistant = () => {

    const [busy, setBusy] = useState(false);
    const [error, setError] = useState(null);
    const [query, setQuery] = useState('');
    const [results, setResults] = useState(null);

    const doSearch = async () => {
        setError(null);
        setBusy(true);
        setResults(null)
        try {
            let results = await search(query);
            setResults(results);
            setBusy(false);
        } catch (e) {
            setError(e.message);
            setBusy(false);
        }
    }

    const debouncedEventHandler = useMemo(
        () => debounce(doSearch, 300)
        , [query]);

    // after unmounting
    useEffect(() => {
        return () => {
            debouncedEventHandler.cancel();
        }
    }, [query]);

    return <>
        <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
            <div style={{display: "flex", gap: "4px", alignItems: "center"}}>
                <Input startAdornment={IconSearch} onKeyUp={debouncedEventHandler} style={{width: "700px"}} type="text"
                       value={query} onChange={e => setQuery(e.target.value)} placeholder="Start typing... when you stop you'll see results"/>
                {/*<select value={selectedSearchType} onChange={e=>setSelectedSearchType(e.target.value)} onBlur={debouncedEventHandler}>
                    {searchTypes.map((searchType) => <option key={searchType} value={searchType}>{searchType}</option>)}
                </select>*/}
                {error && <div style={{color: alertColors.error}}>{error}</div>}
            </div>
        </div>
        <hr/>
        {results && <div>
            <div style={{paddingBottom: "0px"}}>
                <SlideResults results={results}/>
            </div>
        </div>}
        {results === null && <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
            <div style={{height: "50%", width: "50%"}}>
                <IllustrationNothingToView/>
            </div>
        </div>}
    </>
}