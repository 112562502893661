import React from "react";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import {seismicTheme, Theme} from '@seismic/mantle'
import { VisualizationsTheme, seismicVisualizationsTheme } from '@seismic/visualizations';
import {Web} from "./pages/Web";
import {Auth} from "./pages/Auth";
import {SignIn} from "./pages/SignIn";
import "./App.css";

export default function App() {
    
    return (
        <>
            <Theme theme={seismicTheme({useGlobal: true, defaultPortalContainerSelector: '#portals'})}>
                <VisualizationsTheme theme={seismicVisualizationsTheme({ useGlobal: true })}>
                <Router>
                    <Routes>
                        <Route path={"/Auth/*"} element={<Auth/>}/>
                        <Route path={"/SignIn/*"} element={<SignIn/>}/>
                        <Route path={"/*"} element={<Web/>}/>
                    </Routes>
                </Router>
                <div id="portals"></div>
                </VisualizationsTheme>
            </Theme>
 
        </>
    );
}
