import React from "react";
import {ContentCard, ContentChip, IconHost, Banner} from "@seismic/mantle";
import './Components.css'
import { marked} from "marked";
import {useSelector} from "react-redux";

const GenerativeAIIcon = () => {
    return <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M23.7511 12.0001C23.7508 10.6581 22.8594 9.47955 21.5681 9.11408C22.2017 8.49957 22.4173 7.57122 22.1193 6.74037C21.8213 5.90953 21.0649 5.32981 20.1851 5.25808C20.063 5.1208 19.9247 4.9989 19.7731 4.89508C20.3862 3.40177 19.7077 1.69106 18.2377 1.02393C16.7677 0.356795 15.0334 0.972444 14.3131 2.41708C13.8891 1.84064 13.2162 1.50025 12.5006 1.50025C11.785 1.50025 11.1121 1.84064 10.6881 2.41708C9.96779 0.972444 8.23347 0.356795 6.76351 1.02393C5.29355 1.69106 4.61504 3.40177 5.2281 4.89508C5.07665 4.99866 4.9386 5.12059 4.8171 5.25808C3.93765 5.33029 3.18159 5.90991 2.88349 6.74044C2.58539 7.57098 2.8003 8.4991 3.4331 9.11408C2.14182 9.47974 1.25049 10.6585 1.25049 12.0006C1.25049 13.3426 2.14182 14.5214 3.4331 14.8871C2.80006 15.5018 2.585 16.43 2.88317 17.2605C3.18133 18.091 3.93762 18.6704 4.8171 18.7421C4.93907 18.8791 5.07706 19.001 5.2281 19.1051C4.61504 20.5984 5.29355 22.3091 6.76351 22.9762C8.23347 23.6434 9.96779 23.0277 10.6881 21.5831C11.1121 22.1595 11.785 22.4999 12.5006 22.4999C13.2162 22.4999 13.8891 22.1595 14.3131 21.5831C15.0334 23.0277 16.7677 23.6434 18.2377 22.9762C19.7077 22.3091 20.3862 20.5984 19.7731 19.1051C19.9246 19.0015 20.0626 18.8796 20.1841 18.7421C21.0641 18.6712 21.8211 18.0918 22.1194 17.2609C22.4177 16.4301 22.202 15.5015 21.5681 14.8871C22.8597 14.5215 23.7513 13.3424 23.7511 12.0001Z"
              stroke="#0A11B0" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M14.0001 5.25L8.09012 12.132C7.99534 12.2434 7.97398 12.3998 8.03541 12.5325C8.09685 12.6652 8.22985 12.7502 8.37612 12.75H11.0001V18.75L16.9101 11.868C17.0048 11.7567 17.0262 11.6006 16.965 11.468C16.9039 11.3353 16.7712 11.2502 16.6251 11.25H14.0001V5.25Z"
              stroke="#0A11B0" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
}

export const GenerativeMain = () => {
    
    const status = useSelector(state => state.search.generativeTextStatus);
    const error = useSelector(state => state.search.generativeTextError);
    const text = useSelector(state => state.search.generativeText);
    
    return <div style={{paddingBottom: "8px", width: "100%"}}>
        <div style={{borderRadius: "8px", border: "1px solid #0A11B0", backgroundColor: "#E6F0F4", padding: "12px", minHeight: "60px"}}>
            <div style={{display: "flex", gap: "8px"}}>
                <GenerativeAIIcon />
                <div style={{color: "#343A40", fontSize: "18px", fontStyle: "normal", fontWeight: 600, lineHeight: "24px"}}>Generative AI is experimental. Info quality may vary.</div>
            </div>
            {status === "failed" && <div style={{paddingTop: "8px"}}><ErrorInfo error={error} /></div>}
            {status === "idle" ? <div style={{paddingTop: "8px"}}>
                    <div className="skeleton skeleton-text"></div>
                    <div className="skeleton skeleton-text"></div>
                </div> :
                status !== "failed" && <pre className="generative">{text}</pre>}
        </div>
    </div>
}

export const ErrorInfo = ({error}) => {
    return error && <div style={{width: "100%"}}><Banner variant="error" message={error}/></div>
}

export const Header = ({title, seeMore}) =>
    <div style={{display: "flex", alignContent: "center", justifyContent: "space-between"}}>
        <div style={{color: "#343A40", paddingBottom: "8px", fontSize: "18px", fontStyle: "normal", fontWeight: 600, lineHeight: "24px"}}>{title}</div>
        {false && seeMore && <a style={{color: "#0065FF", fontSize: "14px", fontWeight: 600 }}>See More</a>}
    </div>

export const CarouselMain = ({title, items, error, status, maxItems= 5}) => {
    return <div>
        <Header title={title} seeMore={items.length > maxItems} />
        <div style={{paddingBottom: "8px", display: "flex", gap: "16px"}}>
            <ErrorInfo error={error} />
            <Loading status={status} type="carousel" count={maxItems} />
            <NoResults status={status} items={items} />
            {status === "succeeded" && items.slice(0, maxItems).map(item => <CarouselItem key={item.id} item={item} useMarkdown={item.type === "Lesson"} />)}
        </div>
    </div>
}

export const ListMain = ({title, items, error, status, maxItems = 4}) => {
    return <div>
        <Header title={title} seeMore={items.length > maxItems}/>
        <div style={{paddingBottom: "8px"}}>
            <ErrorInfo error={error} />
            <Loading status={status} type="main" count={maxItems} />
            <NoResults status={status} items={items} />
            {status === "succeeded" && items.slice(0, maxItems).map(item => <MainItem key={item.id} item={item} useMarkdown={item.type === "Lesson"} />)}
        </div>
    </div>
}

export const ListPanel = ({title, items, error, status, maxItems = 3}) => {
    return <div>
        <Header title={title} seeMore={items.length > maxItems} />
        <div style={{paddingBottom: "8px"}}>
            <ErrorInfo error={error} />
            <Loading status={status} type="panel" count={maxItems} />
            <NoResults status={status} items={items} />
            {status === "succeeded" && items.slice(0, maxItems).map(item => <PanelItem key={item.id} item={item} useMarkdown={item.type === "Lesson"} />)}
        </div>
    </div>
}

const NoResults = ({status, items}) => {
    return status === "succeeded" && items.length === 0 && <div style={{color: "#343A40", fontSize: "14px", fontStyle: "normal", fontWeight: 400, lineHeight: "18px"}}>No results found.</div>
}

export const FaqItem = ({item}) => {
    return <div style={{padding: "12px", backgroundColor: "white", borderRadius: "8px", width: "242px", height: "124px"}}>
        <div style={{color: "#343A40", paddingBottom: "8px", fontSize: "14px", fontStyle: "normal", fontWeight: 600, lineHeight: "18px", maxHeight: "36px", textOverflow: "ellipsis", overflow: "hidden", display: "-webkit-box", WebkitLineClamp: 2, WebkitBoxOrient: "vertical", whiteSpace: "normal"}}>{item.title}</div>
        <div style={{paddingTop: "10px", paddingBottom: "8px", color: "#343A40", fontSize: "12px", fontStyle: "normal", fontWeight: 400, lineHeight: "14px", maxHeight: "66px", textOverflow: "ellipsis", overflow: "hidden", display: "-webkit-box", WebkitLineClamp: 4, WebkitBoxOrient: "vertical", whiteSpace: "normal"}}>
            <span dangerouslySetInnerHTML={{__html: marked(item.description)}}></span></div>
    </div>
}

export const FaqSideItem = ({item}) => {
    return <div style={{paddingBottom: "8px"}}>
        <div style={{padding: "12px", backgroundColor: "white", borderRadius: "8px", height: "112px"}}>
            <div style={{color: "#343A40", paddingBottom: "8px", fontSize: "14px", fontStyle: "normal", fontWeight: 600, lineHeight: "18px", maxHeight: "36px", textOverflow: "ellipsis", overflow: "hidden", display: "-webkit-box", WebkitLineClamp: 2, WebkitBoxOrient: "vertical", whiteSpace: "normal"}}>{item.title}</div>
            <div style={{paddingTop: "10px", paddingBottom: "8px", color: "#343A40", fontSize: "12px", fontStyle: "normal", fontWeight: 400, lineHeight: "14px", maxHeight: "66px", textOverflow: "ellipsis", overflow: "hidden", display: "-webkit-box", WebkitLineClamp: 4, WebkitBoxOrient: "vertical", whiteSpace: "normal"}}>{item.description}</div>
        </div>
    </div>
}

export const Loading = ({status, type, count}) => {
    return (status === "loading" || status === "idle") && Array.from(Array(count).keys()).map((_, index) => { 
        if(type === "main")
            return <LoadingMainItem key={index} />
        else if(type === "panel")
            return <LoadingPanelItem key={index} />
        else if(type === "carousel")
            return <LoadingCarouselItem key={index} />
        else
            return <div>{type}</div>
    })
}

export const LoadingCarouselItem = () => {
    return <div style={{height: "240px", width: "200px"}}>
        <div className="skeleton skeleton-text"></div>
        <div className="skeleton skeleton-text"></div>
        <div className="skeleton skeleton-text"></div>
        <div className="skeleton skeleton-text"></div>
        <div className="skeleton skeleton-text"></div>
        <div className="skeleton skeleton-text"></div>
        <div className="skeleton skeleton-text"></div>
        <div className="skeleton skeleton-text"></div>
    </div>
}

export const CarouselItem = ({item, useMarkdown}) => {

    if(item.type === "faq")
        return <FaqItem item={item} />
    return <div style={{minHeight: "240px", maxHeight: "240px", height: "240px"}}>
        <ContentCard style={{minHeight: "240px", maxHeight: "240px", height: "240px"}}
                     descriptionProps={{
                         children: item.description
                     }}
                     src={item.thumbnail}
                     titleProps={{
                         href: item.url,
                         children: useMarkdown ? <span style={{display: ""}} dangerouslySetInnerHTML={{__html: marked(item.title)}}></span> : item.title,
                     }} />
    </div>
}

export const MainItem = ({item, useMarkdown}) => {
    if(item.type === "faq")
        return <FaqSideItem item={item} />

    return <div style={{paddingBottom: "8px"}}>
        <ContentChip
            imageUrl={item.thumbnail}
            startAdornment={item.format && <IconHost category="content-icons-v2" params={{ format: item.format }} size={20} />}
            title={useMarkdown ? <span style={{display: ""}} dangerouslySetInnerHTML={{__html: marked(item.title)}}></span> : item.title}
        />
    </div>
}

export const PanelItem = ({item, useMarkdown}) => {
    return <MainItem item={item} useMarkdown={useMarkdown} />
}

export const LoadingMainItem = () => {
    return <div style={{}}>
        <div style={{display: "flex", gap: "8px"}}>
            <div style={{padding: "10px", width: "76px", height: "76px", borderRadius:"4px", marginBottom: "10px"}} className="skeleton"></div>
            <div style={{flex: 1}}>
                <div className="skeleton skeleton-text"></div>
                <div className="skeleton skeleton-text"></div>
                <div className="skeleton skeleton-text"></div>
            </div>
        </div>
    </div>
}
export const LoadingPanelItem = () => {
    return <LoadingMainItem />
}