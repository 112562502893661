import {Tooltip} from "@seismic/mantle";
import React from "react";

export const SlideResults = ({results}) => {

    return <div style={{display: "flex", flexWrap: "wrap", gap: "8px", paddingTop: "8px"}}>
        {results.map(a => <div key={a.id}>
            <div style={{
                padding: "8px",
                border: "1px solid #cfd4da",
                borderRadius: "4px",
                width: "520px",
                height: "359.5px"
            }}>
                <div style={{display: "flex", justifyContent: "space-between", padding: "4px 0px"}}>
                    <div>
                        <Tooltip content={<div>{a.chunk}</div>} arrow={false} size="lg" variant="light" position="top" interactive={true} interactiveDelay={200}>
                            <a href={a.downloadUrl} target="_blank">{a.title} (Slide {a.page_number + 1})</a>
                        </Tooltip>
                    </div>
                    <div
                        style={{display: "none"}}>Score: {a.score.toString().substring(0, 5)}</div>
                </div>
                <img style={{width: "500px", height: "312.5px"}} src={a.thumbnail_url} alt={a.name}/>
            </div>
        </div>)}
    </div>
}
