import React, { useEffect, useState } from "react";
import {CenteredSpinner} from "../components/CenteredSpinner";
import {setAccessToken} from "../slices/authSlice";
import {useDispatch} from 'react-redux';

//This will likely go away and be handled behind the scenes by client credentials flow.
export const Auth = (props) => {

    const dispatch = useDispatch();
    const [message, setMessage] = useState(null);
    
    const getHashParams = () => {
        const hashParams = {};
        const r = /([^&;=]+)=?([^&;]*)/g;
        const q = window.location.hash.substring(1);
        let e = r.exec(q);
        while (e) {
            hashParams[e[1]] = decodeURIComponent(e[2]);
            e = r.exec(q);
        }
        return hashParams;
    }
    
    const getQueryStringParams = () => {
        const queryStringParams = {};
        const r = /([^&;=]+)=?([^&;]*)/g;
        const q = window.location.search.substring(1);
        let e = r.exec(q);
        while (e) {
            queryStringParams[e[1]] = decodeURIComponent(e[2]);
            e = r.exec(q);
        }
        return queryStringParams;
    }

    const loadAccessToken = () => {
        setMessage("Loading access token...")
        let params = getHashParams();
        let accessToken = params.access_token;
        if (accessToken != null) {
            setMessage("Found access token so redirecting...");
            dispatch(setAccessToken(accessToken));
            let redirect = getQueryStringParams().redirect ?? "";
            window.location.replace("/" + redirect);
        }
        else {
            setMessage("No Access Token Found!");
        }
    }

    useEffect(() => {
        loadAccessToken();
    }, []);

    return <div>
        <CenteredSpinner message={message} />
    </div>
}