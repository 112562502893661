import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {
    getLessons,
    getLiveSocialContent,
    getContent,
    getWorkspaceContent,
    getSlides,
    getKnowledge,
    getLayout,
    getTenantInfo,
    streamGenerativeAi,
    streamGenerativeAi2
} from '../api/client';
import {addStandardCases} from "./reduxHelper";
import {getSearchHistory, setSearchHistory} from "../helpers/searchHistoryManager";

const mapSearchResult = (a) => ({
    id: a.VersionId,
    type: "content",
    url: a.Locations[0].UrlLink,
    format: a.Format === "JSON" ? a.ContentTypes[0] : a.Format,
    thumbnail: a.ThumbnailUrl,
    title: a.Name,
    description: a.Description,
    isPromoted: a.IsPromoted
});

export const fetchLessons = createAsyncThunk('fetchLessons', async (arg, { getState }) => {
    let state = getState();
    let res = await getLessons(state.search.query);
    if(res.searchResults) {
        return res.searchResults.cards.map(a => ({
            id: a.ext_id,
            type: a.type,
            url: a.accessories.url,
            thumbnail: a.accessories.thumbnail.image_url,
            title: a.elements.find(x => x.type === "header")?.text,
            description: a.elements.find(x => x.type === "markdown")?.text
        }));
    } else {
        let error = res?.message ?? res?.errorMessage ?? "Unknown error";
        if(error.includes("Search Federation extension is not enabled"))
            return [];
        if(error.includes("Search Endpoint in Search Federation extension"))
            return [];
        throw new Error(error);
    }
})

export const fetchLiveSocialContent = createAsyncThunk('fetchLiveSocialContent', async (arg, { getState }) => {
    let state = getState();
    let res = await getLiveSocialContent(state.search.query);
    return res.items.map((a, index)=>({
        id: index,
        type: "livesocial",
        url: a.Link,
        thumbnail: a.Image,
        title: a.Title,
        description: a.Description
    }));
})

export const fetchTenantInfo = createAsyncThunk('fetchTenantInfo', async (arg, { getState }) => {
    let state = getState();
    return await getTenantInfo(state.search.query);
})

export const fetchContent = createAsyncThunk('fetchContent', async (arg, { getState }) => {
    let state = getState();
    let res = await getContent(state.search.query);
    return res.ServiceResult.Documents.map(a => mapSearchResult(a));
})

export const fetchWorkspaceContent = createAsyncThunk('fetchWorkspaceContent', async (arg, { getState }) => {
    let state = getState();
    let res = await getWorkspaceContent(state.search.query);
    return res.ServiceResult.Documents.map(a=>mapSearchResult(a))
})

export const fetchSlides = createAsyncThunk('fetchSlides', async (arg, { getState }) => {
    let state = getState();
    let res = await getSlides(state.search.query);
    return res.map(a=>({
        id: a.id,
        type: "slide",
        url: "https://quake.seismic.com/app/#/content/"+a.contentVersionId+"/slide/"+a.slideNumber+"/", //No idea what this is
        thumbnail: a.imageUrl,
        title: a.name,
        description: a.description,
        format: a.format,
        text: a.text,
        slideNumber: a.slideNumber,
        score: a.newScore
    }));
})

export const fetchKnowledge = createAsyncThunk('fetchKnowledge', async (arg, { getState }) => {
    let state = getState();
    let res = await getKnowledge(state.search.query);
    return res.searchResults.cards.map(a=>({
        id: a.ext_id,
        type: "faq",
        url: a.accessories.url,
        thumbnail: a.accessories.thumbnail.image_url,
        title: a.elements.find(x => x.type === "header")?.text,
        description: a.elements.find(x => x.type === "markdown")?.text,
        label: a.accessories.thumbnail.label,
        labelType: a.accessories.thumbnail.label_type,
        footer: a.accessories.footer.text
    }));
})

export const fetchLayout = createAsyncThunk('fetchLayout', async (arg, { getState }) => {
    let state = getState();
    let res = await getLayout(state.search.query);
    return res;
})

const getRandomQuery = () => {
    const list = [
        "login for ste",
        "chat gpt",
        "guided assembly",
        "dynamics",
        "salesforce",
    ];
    return list[Math.floor(Math.random() * list.length)];
}

export const searchSlice = createSlice({
    name: 'search',
    initialState: {
        query: getRandomQuery(),
        searchHistory: getSearchHistory(),
        tenantInfoStatus: "idle",
        tenantInfoError: null,
        tenantInfo: null,
        layoutStatus: "idle",
        layoutError: null,
        layout: null,
        generativeTextStatus: "idle",
        generativeTextError: null,
        generativeText: null,
        lessonsStatus: "idle",
        lessonsError: null,
        lessons: [],
        liveSocialContentStatus: "idle",
        liveSocialContentError: null,
        liveSocialContent: [],
        contentStatus: "idle",
        contentError: null,
        content: [],
        workspaceContentStatus: "idle",
        workspaceContentError: null,
        workspaceContent: [],
        slidesStatus: "idle",
        slidesError: null,
        slides: [],
        knowledgeStatus: "idle",
        knowledgeError: null,
        knowledge: [],
    },
    reducers: {
        setQuery: (state, action) => {
            state.query = action.payload;
        },
        appendSearchHistory: (state, action) => {
            let newSearchHistory = (state.searchHistory ?? []).filter(a => a.toLowerCase() !== action.payload.toLowerCase())
            newSearchHistory.unshift(action.payload);
            state.searchHistory = newSearchHistory;
            setSearchHistory(newSearchHistory);
        },
        removeSearchHistory: (state, action) => {
            let newSearchHistory = (state.searchHistory ?? []).filter(a => a.toLowerCase() !== action.payload.toLowerCase())
            state.searchHistory = newSearchHistory;
            setSearchHistory(newSearchHistory);
        },
        noLessons: (state, action) => {
            state.lessonsStatus = "succeeded";
            state.lessonsError = null;
            state.lessons = [];
        },
        noKnowledge: (state, action) => {
            state.knowledgeStatus = "succeeded";
            state.knowledgeError = null;
            state.knowledge = [];
        },
        clearGenerativeText: (state, action) => {
            state.generativeTextStatus = "idle";
            state.generativeTextError = null;
            state.generativeText = "";
        },
        appendGenerativeText: (state, action) => {
            state.generativeTextStatus = "loading";
            state.generativeText += action.payload.replaceAll("\\n", "\n");
        },
        generativeTextComplete: (state, action) => {
            state.generativeTextStatus = "succeeded";
            state.generativeText = state.generativeText?.trimEnd();
        },
        generativeTextError: (state, action) => {
            state.generativeTextStatus = "loading";
            state.generativeText = state.generativeText?.trimEnd();
            state.generativeTextError = action.payload;
        }
    },
    extraReducers(builder) {
        
        //fetchLessons
        addStandardCases(builder, fetchLessons,
            (val, state) => state.lessonsStatus = val,
            (state) => state.lessons = [],
            (val, state) => state.lessons = val,
            (val, state) => state.lessonsError = val
        )
        
        //fetchLiveSocialContent
        addStandardCases(builder, fetchLiveSocialContent,
            (val, state) => state.liveSocialContentStatus = val,
            (state) => state.liveSocialContent = [],
            (val, state) => state.liveSocialContent = val,
            (val, state) => state.liveSocialContentError = val
        )
        
        //fetchContent
        addStandardCases(builder, fetchContent,
            (val, state) => state.contentStatus = val,
            (state) => state.content = [],
            (val, state) => {
                state.content = val
            },
            (val, state) => state.contentError = val
        )
        
        //fetchWorkspaceContent
        addStandardCases(builder, fetchWorkspaceContent,
            (val, state) => state.workspaceContentStatus = val,
            (state) => state.workspaceContent = [],
            (val, state) => state.workspaceContent = val,
            (val, state) => state.workspaceContentError = val
        )

        //fetchSlides
        addStandardCases(builder, fetchSlides,
            (val, state) => state.slidesStatus = val,
            (state) => state.slides = [],
            (val, state) => state.slides = val,
            (val, state) => state.slidesError = val
        )

        //fetchKnowledge
        addStandardCases(builder, fetchKnowledge,
            (val, state) => state.knowledgeStatus = val,
            (state) => state.knowledge = [],
            (val, state) => state.knowledge = val,
            (val, state) => state.knowledgeError = val
        )

        //fetchLayout
        addStandardCases(builder, fetchLayout,
            (val, state) => state.layoutStatus = val,
            (state) => state.layout = null,
            (val, state) => state.layout = val,
            (val, state) => state.layoutError = val
        )

        //fetchTenantInfo
        addStandardCases(builder, fetchTenantInfo,
            (val, state) => state.tenantInfoStatus = val,
            (state) => state.tenantInfo = null,
            (val, state) => state.tenantInfo = val,
            (val, state) => state.tenantInfoError = val
        )
    }
})

export const { setQuery, clearGenerativeText, appendGenerativeText, generativeTextComplete, generativeTextError, noLessons, noKnowledge, appendSearchHistory, removeSearchHistory } = searchSlice.actions;

export const fetchGenerativeAi = (query) => async (dispatch, state) => {
    dispatch(clearGenerativeText())
    await streamGenerativeAi(query, (message) => {
        dispatch(appendGenerativeText(message.data))
    }, (err) => {
        dispatch(generativeTextError(err))
        throw err
    }, () => {
        dispatch(generativeTextComplete())
    });
}

export const fetchGenerativeAi2 = (data) => async (dispatch, state) => {
    dispatch(clearGenerativeText())
    await streamGenerativeAi2(data, (message) => {
        dispatch(appendGenerativeText(message.data))
    }, (err) => {
        dispatch(generativeTextError(err))
        throw err
    }, () => {
        dispatch(generativeTextComplete())
    });
}

export default searchSlice.reducer