import React from "react";
import {GenerativeMain, CarouselMain, ListPanel} from "../widgets/Components";

export const LayoutSelector = ({layout, data}) => {

    const getPriority = (x) => {
        
        let newPriority = []
        
        for(let i = 0; i < x.length; i++) {
            let item = x[i];
            if (item === "livesocial" && (data.liveSocialContentStatus === "loading" || data.liveSocialContentStatus === "failed" || data.liveSocialContent.length > 0)) {
                newPriority.push("livesocial")
            } else if (item === "library" && (data.contentStatus === "loading" || data.contentStatus === "failed" || data.content.length > 0)) {
                newPriority.push("library")
            } else if (item === "workspace" && (data.workspaceContentStatus === "loading" || data.workspaceContentStatus === "failed" || data.workspaceContent.length > 0)) {
                newPriority.push("workspace")
            } else if (item === "lessonly" && (data.lessonsStatus === "loading" || data.lessonsStatus === "failed" || data.lessons.length > 0)) {
                newPriority.push("lessonly")
            } else if (item === "knowledge" && (data.knowledgeStatus === "loading" || data.knowledgeStatus === "failed" || data.knowledge.length > 0)) {
                newPriority.push("knowledge")
            }
        }
        
        return newPriority;
    }
    
    const priority = getPriority(layout.priority);

    return <>
        {priority.length === 1 && <Layout1 priority={priority} data={data}/>}
        {priority.length === 2 && <Layout2 priority={priority} data={data}/>}
        {priority.length === 3 && <Layout3 priority={priority} data={data}/>}
        {priority.length === 4 && <Layout4 priority={priority} data={data}/>}
        {priority.length === 5 && <Layout5 priority={priority} data={data}/>}
    </>
}

const getDataToRender = (data, item) => {
    if(item === "lessonly") return {
        text: "Lessons",
        items: data.lessons,
        error: data.lessonsError,
        status: data.lessonsStatus
    }
    if(item === "livesocial") return {
        text: "From the Internet",
        items: data.liveSocialContent,
        error: data.liveSocialContentError,
        status: data.liveSocialContentStatus
    }
    if(item === "workspace") return {
        text: "Your Workspace",
        items: data.workspaceContent,
        error: data.workspaceContentError,
        status: data.workspaceContentStatus
    }
    if(item === "library") return {
        text: "Published Content",
        items: data.content,
        error: data.contentError,
        status: data.contentStatus
    }
    if(item === "knowledge") return {
        text: "Knowledge",
        items: data.knowledge,
        error: data.knowledgeError,
        status: data.knowledgeStatus
    }
    console.log("Unknown Item: ", item);
    return {}
};

const Main = ({item}) => {

    return <>
        <CarouselMain title={item.text} items={item.items} error={item.error} status={item.status} maxItems={6} />
    </>
}

const SecondaryMain = ({item}) => {

    return <>
        <CarouselMain title={item.text} items={item.items} error={item.error} status={item.status} maxItems={6} />
    </>
}

const Side = ({item}) => {

    return <>
        <ListPanel title={item.text} items={item.items} error={item.error} status={item.status} maxItems={5} />
    </>
}

const Layout1 = ({priority, data}) => {

    return <div style={{width: "100%", display: "flex", gap: "16px"}}>
        <div style={{flex: 1}}>
            <GenerativeMain />
            <Main item={getDataToRender(data, priority[0])} />
        </div>
    </div>
}

const Layout2 = ({priority, data}) => {
    return <div style={{width: "100%", display: "flex", gap: "16px"}}>
        <div style={{flex: 1}}>
            <GenerativeMain />
            <Main item={getDataToRender(data, priority[0])} />
            <Main item={getDataToRender(data, priority[1])} />
        </div>
    </div>
}

const Layout3 = ({priority, data}) => {
    return <div style={{width: "100%", display: "flex", gap: "16px"}}>
        <div style={{flex: 1}}>
            <GenerativeMain />
            <Main item={getDataToRender(data, priority[0])} />
            <Main item={getDataToRender(data, priority[1])} />
        </div>
        <div style={{width: "400px"}}>
            <Side item={getDataToRender(data, priority[2])} />
        </div>
    </div>
}

const Layout4 = ({priority, data}) => {
    return <div style={{width: "100%", display: "flex", gap: "16px"}}>
        <div style={{flex: 1}}>
            <GenerativeMain />
            <Main item={getDataToRender(data, priority[0])} />
            <Main item={getDataToRender(data, priority[1])} />
        </div>
        <div style={{width: "400px"}}>
            <Side item={getDataToRender(data, priority[2])} />
            <Side item={getDataToRender(data, priority[3])} />
        </div>
    </div>
}

const Layout5 = ({priority, data}) => {
    return <div style={{width: "100%", display: "flex", gap: "16px"}}>
        <div style={{flex: 1}}>
            <GenerativeMain />
            <Main item={getDataToRender(data, priority[0])} />
            <Main item={getDataToRender(data, priority[1])} />
            <SecondaryMain item={getDataToRender(data, priority[2])} />
        </div>
        <div style={{width: "400px"}}>
            <Side item={getDataToRender(data, priority[3])} />
            <Side item={getDataToRender(data, priority[4])} />
        </div>
    </div>
}