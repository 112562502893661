import React from "react";
import {Spinner} from "@seismic/mantle";

export const CenteredSpinner = (props) => {

    return <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100vh"}}>
        <div style={{backgroundColor: "white", border: "1px solid #CFD4DA", borderRadius: "8px", padding: "24px", textAlign: "center"}}>
            <Spinner />
            {props?.message ? <div style={{padding: "4px"}}>{props.message}</div> : null}
        </div>
    </div>

}

export const CenteredSpinner2 = (props) => {

    return <div style={{display: "grid"}}>
        <div style={{padding: "16px", textAlign: "center"}}>
            <Spinner />
            {props?.message ? <div style={{padding: "16px"}}>{props.message}</div> : null}
        </div>
    </div>

}